import { cn } from "../../../lib/utils";
import { useEditor } from "novel";
import { Check, Trash } from "lucide-react";
import { useEffect, useRef } from "react";
import {Portal,Content} from '@radix-ui/react-popover';
import { Popover,PopoverTrigger } from "../../UI/popover";
import { Button } from "../../UI/Button2";

export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
export function getUrlFromString(str) {
  if (isValidUrl(str)) return str;
  try {
    if (str.includes(".") && !str.includes(" ")) {
      return new URL(`https://${str}`).toString();
    }
  } catch (e) {
    return null;
  }
}


export const LinkSelector = ({ open, onOpenChange, containerRef }) => {
  const inputRef = useRef(null);
  const { editor } = useEditor();

  // Autofocus on input by default
  useEffect(() => {
    inputRef.current && inputRef.current?.focus();
  });
  if (!editor) return null;

  return (
    <Popover modal={true} open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button variant='ghost' className='gap-2 rounded-none border-none'>
          <p className='text-base'>↗</p>
          <p
            className={cn("underline decoration-stone-400 underline-offset-4", {
              "text-blue-500": editor.isActive("link"),
            })}>
            Link
          </p>
        </Button>
      </PopoverTrigger>
      <Portal container={containerRef.current}>
      <Content align='start' className='w-60 p-0' sideOffset={10}>
        <form
          onSubmit={(e) => {
            const target = e.currentTarget;
            e.preventDefault();
            const input = target[0] ;
            const url = getUrlFromString(input.value);
            url && editor.chain().focus().setLink({ href: url }).run();
            onOpenChange(false);
          }}
          className='flex  p-1 '>
          <input
            ref={inputRef}
            type='text'
            placeholder='Paste a link'
            className='flex-1 bg-background p-1 text-sm outline-none'
            defaultValue={editor.getAttributes("link").href || ""}
          />
          {editor.getAttributes("link").href ? (
            <Button
              size='icon'
              variant='outline'
              type='button'
              className='flex h-8 items-center rounded-sm p-1 text-red-600 transition-all hover:bg-red-100 dark:hover:bg-red-800'
              onClick={() => {
                editor.chain().focus().unsetLink().run();
                onOpenChange(false);
              }}>
              <Trash className='h-4 w-4' />
            </Button>
          ) : (
            <Button size='icon' className='h-8'>
              <Check className='h-4 w-4' />
            </Button>
          )}
        </form>
      </Content>
      </Portal>
    </Popover>
  );
};
