import './App.css';
import Routehandler from './components/Routehandler';

function App() {

  return (
    <div className='w-full h-screen relative'>
      <Routehandler />
    </div>
  )

}

export default App;
